$icon-font-path: "/assets/fonts/bootstrap/";
$fa-font-path: "/assets/fonts/font-awesome/";
$container-large-desktop: 1200px;

$btn-primary-bg: #00b5ad;
$btn-primary-border: #00b5ad;

$label-primary-bg: #00b5ad;

$pagination-active-bg: #00b5ad;
$pagination-active-border: #00b5ad;

$pagination-color: #00b5ad;

$input-border-focus: #00b5ad;

$link-color: #12c4c5;
$link-hover-color: #22ddde;

@import "vendor/markdown";
@import "vendor/nprogress";
@import "vendor/prism";
@import "vendor/animate";
@import "vendor/share";
@import "vendor/bootstrap-switch";
@import "vendor/messenger";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";
@import "vendor/sweetalert";

