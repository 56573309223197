
ul.messenger {
  margin: 0;
  padding: 0;
}
ul.messenger > li {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.messenger.messenger-empty {
  display: none;
}
ul.messenger .messenger-message {
  overflow: hidden;
  *zoom: 1;
}
ul.messenger .messenger-message.messenger-hidden {
  display: none;
}
ul.messenger .messenger-message .messenger-phrase, ul.messenger .messenger-message .messenger-actions a {
  padding-right: 5px;
}
ul.messenger .messenger-message .messenger-actions {
  float: right;
}
ul.messenger .messenger-message .messenger-actions a {
  cursor: pointer;
  text-decoration: underline;
}
ul.messenger .messenger-message ul, ul.messenger .messenger-message ol {
  margin: 10px 18px 0;
}
ul.messenger.messenger-fixed {
  position: fixed;
  z-index: 10000;
}
ul.messenger.messenger-fixed .messenger-message {
  min-width: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
ul.messenger.messenger-fixed .message .messenger-actions {
  float: left;
}
ul.messenger.messenger-fixed.messenger-on-top {
  top: 20px;
}
ul.messenger.messenger-fixed.messenger-on-bottom {
  bottom: 20px;
}
ul.messenger.messenger-fixed.messenger-on-top, ul.messenger.messenger-fixed.messenger-on-bottom {
  left: 50%;
  width: 800px;
  margin-left: -400px;
}
@media (max-width: 960px) {

  ul.messenger.messenger-fixed.messenger-on-top, ul.messenger.messenger-fixed.messenger-on-bottom {
    left: 10%;
    width: 80%;
    margin-left: 0px;
  }
}
ul.messenger.messenger-fixed.messenger-on-top.messenger-on-right, ul.messenger.messenger-fixed.messenger-on-bottom.messenger-on-right {
  right: 20px;
  left: auto;
}
ul.messenger.messenger-fixed.messenger-on-top.messenger-on-left, ul.messenger.messenger-fixed.messenger-on-bottom.messenger-on-left {
  left: 20px;
  margin-left: 0px;
}
ul.messenger.messenger-fixed.messenger-on-right, ul.messenger.messenger-fixed.messenger-on-left {
  width: 350px;
}
ul.messenger.messenger-fixed.messenger-on-right .messenger-actions, ul.messenger.messenger-fixed.messenger-on-left .messenger-actions {
  float: left;
}
ul.messenger .messenger-spinner {
  display: none;
}
ul.messenger .messenger-clickable {
  cursor: pointer;
}


// flat theme
@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes ui-spinner-rotate-right {
  0% {
    -moz-transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(180deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  75% {
    -moz-transform: rotate(360deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-moz-keyframes ui-spinner-rotate-left {
  0% {
    -moz-transform: rotate(0deg);
  }
  25% {
    -moz-transform: rotate(0deg);
  }
  50% {
    -moz-transform: rotate(180deg);
  }
  75% {
    -moz-transform: rotate(180deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes ui-spinner-rotate-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ui-spinner-rotate-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.messenger-spinner {
  position: relative;
  border-radius: 100%;
}
ul.messenger.messenger-spinner-active .messenger-spinner .messenger-spinner {
  display: block;
}
.messenger-spinner .messenger-spinner-side {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
}
.messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  border-radius: 999px;
  position: absolute;
  width: 100%;
  height: 100%;
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.messenger-spinner .messenger-spinner-side-left {
  left: 0;
}
.messenger-spinner .messenger-spinner-side-left .messenger-spinner-fill {
  left: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-animation-name: ui-spinner-rotate-left;
  -webkit-animation-name: ui-spinner-rotate-left;
  animation-name: ui-spinner-rotate-left;
  -moz-transform-origin: 0 50% 50%;
  -ms-transform-origin: 0 50% 50%;
  -webkit-transform-origin: 0 50% 50%;
  transform-origin: 0 50% 50%;
}
.messenger-spinner .messenger-spinner-side-right {
  left: 50%;
}
.messenger-spinner .messenger-spinner-side-right .messenger-spinner-fill {
  left: -100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  -moz-animation-name: ui-spinner-rotate-right;
  -webkit-animation-name: ui-spinner-rotate-right;
  animation-name: ui-spinner-rotate-right;
  -moz-transform-origin: 100% 50% 50%;
  -ms-transform-origin: 100% 50% 50%;
  -webkit-transform-origin: 100% 50% 50%;
  transform-origin: 100% 50% 50%;
}

ul.messenger-theme-flat {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  background: #404040;
}
ul.messenger-theme-flat.messenger-empty {
  display: none;
}
ul.messenger-theme-flat .messenger-message {
  -moz-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
  -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
  box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;
  position: relative;
  border: 0px;
  margin-bottom: 0px;
  font-size: 13px;
  background: transparent;
  color: #f0f0f0;
  font-weight: 500;
  padding: 10px 30px 13px 65px;
}
ul.messenger-theme-flat .messenger-message .messenger-close {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #888;
  opacity: 1;
  font-weight: bold;
  display: block;
  font-size: 20px;
  line-height: 20px;
  padding: 8px 10px 7px 7px;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
ul.messenger-theme-flat .messenger-message .messenger-close:hover {
  color: #bbb;
}
ul.messenger-theme-flat .messenger-message .messenger-close:active {
  color: #777;
}
ul.messenger-theme-flat .messenger-message .messenger-actions {
  float: none;
  margin-top: 10px;
}
ul.messenger-theme-flat .messenger-message .messenger-actions a {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  color: #aaa;
  background: #2e2e2e;
  display: inline-block;
  padding: 10px;
  margin-right: 10px;
  padding: 4px 11px 6px;
  text-transform: capitalize;
}
ul.messenger-theme-flat .messenger-message .messenger-actions a:hover {
  color: #f0f0f0;
  background: #2e2e2e;
}
ul.messenger-theme-flat .messenger-message .messenger-actions a:active {
  background: #292929;
  color: #aaa;
}
ul.messenger-theme-flat .messenger-message .messenger-actions .messenger-phrase {
  display: none;
}
ul.messenger-theme-flat .messenger-message .messenger-message-inner:before {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 17px;
  display: block;
  content: " ";
  top: 50%;
  margin-top: -8px;
  height: 13px;
  width: 13px;
  z-index: 20;
}
ul.messenger-theme-flat .messenger-message.alert-success .messenger-message-inner:before {
  background: #5fca4a;
}
ul.messenger-theme-flat .messenger-message.alert-info .messenger-message-inner:before {
  background: #61c4b8;
}
ul.messenger-theme-flat .messenger-message.alert-error .messenger-message-inner:before {
  background: #dd6a45;
}
ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-soon .messenger-spinner {
  width: 32px;
  height: 32px;
  background: transparent;
}
ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-soon .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  background: #dd6a45;
  -moz-animation-duration: 20s;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  opacity: 1;
}
ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-soon .messenger-spinner:after {
  content: "";
  background: #292929;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  display: block;
}
ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-later .messenger-spinner {
  width: 32px;
  height: 32px;
  background: transparent;
}
ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-later .messenger-spinner .messenger-spinner-side .messenger-spinner-fill {
  background: #dd6a45;
  -moz-animation-duration: 600s;
  -webkit-animation-duration: 600s;
  animation-duration: 600s;
  opacity: 1;
}
ul.messenger-theme-flat .messenger-message.alert-error.messenger-retry-later .messenger-spinner:after {
  content: "";
  background: #292929;
  position: absolute;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  top: 3px;
  left: 3px;
  display: block;
}
ul.messenger-theme-flat .messenger-message-slot.messenger-last .messenger-message {
  -moz-border-radius: 4px 4px 0px 0px;
  -webkit-border-radius: 4px;
  border-radius: 4px 4px 0px 0px;
  -moz-box-shadow: inset 48px 0px 0px #292929;
  -webkit-box-shadow: inset 48px 0px 0px #292929;
  box-shadow: inset 48px 0px 0px #292929;
}
ul.messenger-theme-flat .messenger-message-slot.messenger-first .messenger-message {
  -moz-border-radius: 0px 0px 4px 4px;
  -webkit-border-radius: 0px;
  border-radius: 0px 0px 4px 4px;
  -moz-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
  -webkit-box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
  box-shadow: inset 0px 1px rgba(255, 255, 255, 0.13), inset 48px 0px 0px #292929;
}
ul.messenger-theme-flat .messenger-message-slot.messenger-first.messenger-last .messenger-message {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -moz-box-shadow: inset 48px 0px 0px #292929;
  -webkit-box-shadow: inset 48px 0px 0px #292929;
  box-shadow: inset 48px 0px 0px #292929;
}
ul.messenger-theme-flat .messenger-spinner {
  display: block;
  position: absolute;
  left: 7px;
  top: 50%;
  margin-top: -18px;
  z-index: 999;
  height: 32px;
  width: 32px;
  z-index: 10;
}

ul.messenger a {
    color: #fff;
    text-decoration: underline;
}